<template>
  <div class="container app-content pt-4">
    <Error v-if="error" :error="error" />
    <Loading v-else-if="isLoading" class="mt-4" />
    <template v-else>
      <!-- Page Header -->
      <PageHeader :title="promotion.title" />

      <PromotionNavigation :promotion="promotion" />

      <div class="row mb-4">
        <div class="col-md-3">
          <FromTo :starts_at="promotion.starts_at" :ends_at="promotion.ends_at" show="time" />
        </div>

        <div class="col-md-3">
          <h3>Totals</h3>
          <CardTotalsTable
            :goal="intention.counter"
            :amount="totalDollars"
            :donations="totalDonations"
          />
        </div>

        <div class="col-md-6">
          <h3>Description</h3>
          {{ promotion.description }}
        </div>
      </div>

      <DonationsList
        v-if="can('index', 'donations')"
        :promotion="promotion"
        :striped="true"
        :hover="true"
      />
    </template>

    <Modal id="modal-promotion-edit">
      <template v-slot:title>Edit Promotion</template>

      <template v-slot:body>
        <PromotionForm
          v-if="!isLoading"
          :initial="promotion"
          modal="#modal-promotion-edit"
          @complete="fetchData"
        />
      </template>
    </Modal>

    <Modal id="modal-promotion-delete">
      <template v-slot:title>Delete Promotion</template>

      <template v-slot:body>
        <PromotionDeleteForm
          v-if="!isLoading"
          :id="promotion.promotion_id"
          modal="#modal-promotion-delete"
        />
      </template>
    </Modal>
  </div>
</template>

<script>
/*global $*/
import hasIntentions from 'mixins/hasIntentions';
import hasStatistics from 'mixins/hasStatistics';
import hasTimeDate from 'mixins/hasTimeDate';
// import CardTotalsHorizontal from 'components/CardTotalsHorizontal';
import CardTotalsTable from 'components/CardTotalsTable';
import DonationsList from 'components/donations/DonationsList';
import Error from 'components/Error';
import FromTo from 'components/FromTo';
import Loading from 'components/Loading';
import Modal from 'components/Modal';
import PageHeader from 'components/PageHeader';
import PromotionDeleteForm from 'components/forms/PromotionDeleteForm';
import PromotionForm from 'components/forms/PromotionForm';
import PromotionNavigation from 'components/promotions/PromotionNavigation';

export default {
  name: 'PromotionsShow',
  mixins: [hasIntentions, hasStatistics, hasTimeDate],
  components: {
    // CardTotalsHorizontal,
    CardTotalsTable,
    DonationsList,
    Error,
    FromTo,
    Loading,
    Modal,
    PageHeader,
    PromotionDeleteForm,
    PromotionForm,
    PromotionNavigation,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    showCreateForm: {
      type: Boolean,
      required: false,
      default: false,
    },
    showEditForm: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    campaign() {
      return this.promotion.campaign;
    },
    promotion() {
      return this.$store.getters['promotions/getCurrentPromotion'];
    },
    statistics() {
      return this.promotion.statistics ? this.promotion.statistics : {};
    },
  },
  data() {
    return {
      donations: [],
      donationsMeta: {},
      error: null,
      errorDonations: null,
      isLoading: false,
      isLoadingDonations: false,
      formShown: false,
    };
  },
  created() {
    // fetch the data when the view is created and the data is
    // already being observed
    this.fetchData();
  },
  methods: {
    fetchData() {
      // #TODO lock the page while loading
      this.error = null;
      this.isLoading = true;

      const params = {
        id: this.id,
        params: {
          with: 'campaign;statistics;intentions',
        },
      };

      // console.log('PromotionsShow methods fetchData() :params', params);

      this.$store
        .dispatch('promotions/get', params)
        .then(() => {
          // console.log('PromotionsShow fetchData() promotions/get then');
          this.isLoading = false;
          this.fetchDonations();
        })
        .catch((error) => {
          this.error = this.$errorProcessor(error);
        });
    },
    fetchDonations() {
      this.errorDonations = null;
      this.isLoadingDonations = true;

      const { ends_at, starts_at } = this.promotion;
      const payload = {
        id: this.campaign.campaign_id,
        refreshFavorites: true,
        params: {
          per_page: 0,
          timeslice: `created_at;${this.formatISODate(starts_at)}|${this.formatISODate(ends_at)}`,
        },
        saveToStore: true,
      };

      const where = this.intentionTrackedDonationType();

      if (where) {
        payload.params.where = where;
      }

      this.$store
        .dispatch('campaigns/getDonations', payload)
        .then((response) => {
          // console.log('PromotionsShow fetchData() campaigns/getDonations then', payload, response.data);
          const { data, meta } = response.data;
          this.donations = data;
          this.donationsMeta = meta;
          this.isLoadingDonations = false;

          if (this.formShown == false) {
            this.showForms();
            this.formShown = true;
          }
        })
        .catch((error) => {
          this.errorDonations = this.$errorProcessor(error);
        });
    },
    showForms() {
      if (this.showEditForm) {
        $('#modal-promotion-edit').modal('show');
      } else if (this.showDeleteForm) {
        $('#modal-promotion-delete').modal('show');
      }
    },
  },
};
</script>

<style scoped lang="scss"></style>
