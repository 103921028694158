<template>
  <form @submit.prevent="handleSubmit">
    <FormError v-if="error" :error="error" />
    <div class="form-text">
      <p>Are you sure you want to delete this Promotion? This action is not reversible.</p>
    </div>
    <div class="form-row justify-content-between">
      <div class="col-auto">
        <button type="submit" class="btn btn-success" :disabled="isLocked">
          <font-awesome-icon icon="trash" /> Delete This Promotion?
        </button>
      </div>
      <div class="col-auto">
        <button
          type="button"
          class="btn btn-outline-danger"
          :disabled="isLocked"
          @click="modalClose"
        >
          <font-awesome-icon icon="window-close" /> Cancel
        </button>
      </div>
    </div>
  </form>
</template>

<script>
/*global $*/
import FormError from './FormError';

export default {
  name: 'PromotionDeleteForm',
  components: { FormError },
  props: {
    id: {
      type: Number,
      required: true,
    },
    modal: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      error: false,
      isLocked: false,
    };
  },
  methods: {
    handleSubmit() {
      console.log('PromotionDeleteForm handleSubmit()', this.id);
      // Lock the buttons
      this.isLocked = true;

      const payload = {
        id: this.id,
      };

      // Send to action
      this.$store
        .dispatch('promotions/destroy', payload)
        .then((response) => {
          // on success, close modal, unlock buttons
          console.log('PromotionDeleteForm handleSubmit() then', response);
          this.modalClose();
          this.$router.push({ name: 'promotions' });
        })
        .catch((error) => {
          // on error, highlight errors
          console.error('PromotionDeleteForm handleSubmit() catch', error);
          this.error = this.$errorProcessor(error);
        })
        .finally(() => {
          this.isLocked = false;
        });
    },
    modalClose() {
      // reset the Modal
      this.error = false;
      this.isLocked = false;

      // if we have a modal parent, close it
      if (this.modal) {
        $(this.modal).modal('hide');
      }
    },
  },
};
</script>
