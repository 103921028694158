<template>
  <nav class="navbar navbar-expand-md navbar-light bg-light mb-4">
    <router-link
      v-if="campaign && can('view', 'campaigns')"
      class="btn btn-primary mr-2"
      :to="{ name: 'campaign', params: { id: campaign.campaign_id } }"
      :title="campaign.title"
    >
      <font-awesome-icon icon="bullhorn" /> View Campaign
    </router-link>
    <button
      v-if="can('update', 'promotions')"
      class="btn btn-secondary ml-auto"
      type="button"
      data-toggle="modal"
      data-target="#modal-promotion-edit"
    >
      <font-awesome-icon icon="edit" /> Edit
    </button>
    <button
      v-if="can('destroy', 'promotions')"
      class="btn btn-danger ml-2"
      type="button"
      data-toggle="modal"
      data-target="#modal-promotion-delete"
    >
      <font-awesome-icon icon="trash" />
    </button>
  </nav>
</template>

<script>
export default {
  name: 'PromotionNavigation',
  props: {
    promotion: {
      type: Object,
      required: true,
    },
  },
  computed: {
    campaign() {
      return this.promotion.campaign ?? false;
    },
  },
};
</script>
